<template>
<div class="page-padding" id="cart-container">
  <div id="empty" v-show="this.$store.state.cart.length == 0">
    <p>Your cart is empty!</p>
  </div>

  <div id="cart" v-show="this.$store.state.cart.length != 0 && (orderProcess == undefined || orderProcess == 'customerInfo' || orderProcess == 'selectCountry')">
    <div class="grid-container">
      <div class="row">
        <p style="flex-basis: 35%">Product</p>
        <p style="flex-basis: 30%"> </p>
        <p style="flex-basis: 25%">Costs (EU)</p>
        <p style="flex-basis: 10%"> </p>
      </div>
      <div v-for="item in this.$store.state.cart" :key="item" class="row">
        <p style="flex-basis: 35%" class="title">{{ item.id.split('/')[1] }} {{ item.title }}</p>
        <img style="flex-basis: 30%" :src="$store.state.fileBaseURL + item.id + '/' + item.backdrop_file"/>
        <p style="flex-basis: 25%">
          <!-- {{ item.price_round }}<span v-if="item.price_decimal != ''">,</span>{{ item.price_decimal }} -->
          {{ licenseSum }}
          </p>
        <p style="flex-basis: 10%; text-align:right;" @click="removeFromCart(item)">
          <a>
            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-x" width="36" height="36" viewBox="0 0 24 24" stroke-width="1.5" stroke="#2c3e50" fill="none" stroke-linecap="round" stroke-linejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
            <line x1="18" y1="6" x2="6" y2="18" />
            <line x1="6" y1="6" x2="18" y2="18" />
            </svg>
          </a>
        </p>
      </div>
      <div class="row" v-if="orderProcess == 'selectCountry'">
         <p style="flex-basis: 35%">VAT</p>
        <p style="flex-basis: 30%"></p>
        <p style="flex-basis: 25%">{{ this.vatSum }}</p>
        <p style="flex-basis: 10%"></p>
      </div>
      <div class="row">
         <p style="flex-basis: 35%">Total</p>
        <p style="flex-basis: 30%"></p>
        <p style="flex-basis: 25%" v-if="orderProcess == 'selectCountry'">{{ this.$store.state.cart.length * licenseSum + this.vatSum }}</p>
        <p style="flex-basis: 25%" v-else>{{ this.$store.state.cart.length * licenseSum }}</p>
        <p style="flex-basis: 10%"></p>
      </div>
    </div>
    <div class="button check-out" v-if="orderProcess == 'customerInfo' && !$store.state.$mobile" @click="orderProcess = undefined">
      <h3>Back to licensing</h3>
    </div>

    <div class="button check-out" v-if="orderProcess == 'selectCountry' && !$store.state.$mobile" @click="orderProcess = 'customerInfo'">
      <h3>Back to customer info</h3>
    </div>
  </div>

  <div id="licensing" v-show="this.$store.state.cart.length != 0 && orderProcess == undefined">
    <div class="grid-container">
      <div class="row">
        <p>Licensing</p>
      </div>
      <div class="row drop-down-container">
        <div>
          <p>Desktop License</p>
          <div class="row">
            <p>Number of employees in company</p>
            <select v-model="desktopLicense" id="desktop-license" autofocus>
              <option value="0" selected>1 desktop</option>
              <option value="1">2-3 desktops</option>
              <option value="2">4-6 desktops</option>
              <option value="3">7-10 desktops</option>
              <option value="4">11-15 desktops</option>
              <option value="5">16+ desktops</option>
            </select>
          </div>
        </div>

        <div>
          <p>Web License</p>
          <div class="row">
            <p>Number of visitors per month</p>
            <select v-model="webLicense" id="web-license">
              <option value="0" selected>0</option>
              <option value="1">&#60; 2k web visits per month </option>
              <option value="2">10k web visits per month</option>
              <option value="3">50k web visits per month</option>
              <option value="4">100k web visits per month</option>
              <option value="5">&#62; 150k web visits per month</option>
            </select>
          </div>

        </div>

        <div>
          <p>App License</p>
          <div class="row">
            <p>Number of apps</p>
            <select v-model="appLicense" id="app-license">
              <option value="0" selected>0</option>
              <option value="1">1 apps</option>
              <option value="2">2 apps</option>
              <option value="3">3 apps</option>
              <option value="4">4 apps</option>
              <option value="5">5 apps</option>
            </select>`
          </div>
        </div>

        <div>
          <p>Social Media</p>
          <div class="row">
            <p>Number of followers</p>
            <select v-model="socialLicense" id="social-media-license">
              <option value="0" selected>0</option>
              <option value="1">&#60; 10k followers</option>
              <option value="2">10-25k followers</option>
              <option value="3">25k-50k followers</option>
              <option value="4">50k-250k followers</option>
              <option value="5">250-750k followers</option>
            </select>
          </div>
        </div>

      </div>
    </div>
    <div class="button check-out" v-if="this.$store.state.cart.length != 0" @click="orderProcess = 'customerInfo'">
      <h3>Continue</h3>
    </div>
  </div>

  <div id="customer-info" v-show="orderProcess == 'customerInfo'">
  <div class="grid-container">
    <div class="customer-info-container">
      <div class="row">
        <p>Name</p>
        <input v-model="firstName" type="text" placeholder=""/>
        <p>Last Name</p>
        <input v-model="lastName" type="text" placeholder="" />
        <p>E-mail</p>
        <input v-model="email" type="text" placeholder="" />
      </div>

      <div class="row" v-if="company">
        <p>Company Name</p>
        <input v-model="companyName" type="text" placeholder="" />
        <p>Street + Number </p>
        <input v-model="street" type="text" placeholder="" />
        <p>Postal Code</p>
        <input v-model="postal" type="text" placeholder="" />
        <p>City</p>
        <input v-model="city" type="text" placeholder="" />
        <p>Country</p>
        <input v-model="country" type="text" placeholder="" />
        <p>VAT Number</p>
        <input v-model="vat" type="text" placeholder="" />
      </div>

      <div class="row">
        <p style="cursor: pointer" @click="company = !company">
          <span v-if="!company">Order as business</span>
          <span v-else>Order as customer</span>

          <svg v-if="!company" xmlns="http://www.w3.org/2000/svg" class="inline-block ml-1 icon icon-tabler icon-tabler-chevron-down" width="36" height="36" viewBox="0 7 24 9" stroke-width="1.5" stroke="#2c3e50" fill="none" stroke-linecap="round" stroke-linejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
            <polyline points="6 9 12 15 18 9" />
          </svg>
          <svg v-else xmlns="http://www.w3.org/2000/svg" class="inline-block ml-1 icon icon-tabler icon-tabler-chevron-up" width="36" height="36" viewBox="0 7 24 9" stroke-width="1.5" stroke="#2c3e50" fill="none" stroke-linecap="round" stroke-linejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
            <polyline points="6 15 12 9 18 15" />
          </svg>
        </p>
      </div>
    </div>

    <div id="errors" v-if="errors.length">
      <span v-for="error in errors" class="StickyFat" :key="error">{{ error }} <br /></span>
    </div>

  </div>
    <div class="button check-out" v-if="this.$store.state.cart.length != 0" @click="validate()">
      <h3>Continue</h3>
    </div>
    <div class="button check-out" v-if="orderProcess == 'customerInfo' && $store.state.$mobile" @click="orderProcess = undefined">
      <h3>Back to licensing</h3>
    </div>
</div>

  <div id="customer-info" v-show="orderProcess == 'selectCountry'">
    <div class="row drop-down-container">
    <div>
      <p class="mb-2">Select country</p>
      <div class="row">
        <select v-model="VATcountry" id="desktop-license" autofocus class="!max-w-full">
          <option value="0" selected>Inside The Netherlands</option>
          <option value="1">Outside The Netherlands but in the EU</option>
          <option value="2">Outside the EU </option>
        </select>
      </div>
    </div>
    </div>

    <div class="button check-out" v-if="this.$store.state.cart.length != 0" @click="checkout()">
      <h3>Check Out</h3>
    </div>
    <div class="button check-out" v-if="orderProcess == 'selectCountry' && $store.state.$mobile" @click="orderProcess = 'customerInfo'">
      <h3>Back to customer info</h3>
    </div>
  </div>


  <div v-if="orderProcess == 'loadingCheckout'" id="loading">
      <p>You will be redirected to checkout shortly.</p>
  </div>


</div>



  
</template>

<script>
import isEmail from "validator/lib/isEmail";
import axios from "axios";

export default {
  mounted() {
    this.$store.commit('setFocus', null);
  },
  data() {
    return {
      desktopLicense: 0,
      webLicense: 0,
      appLicense: 0,
      socialLicense: 0,
      VATcountry: 0,
      countryDescription: null,
      orderProcess: undefined,
      company: false,
      errors: [],
      businessOrder: null
    }
  },
  methods: {
    removeFromCart(item) {
      this.$store.commit('removeFromCart', item)
    },
    validate() {
      this.errors = [];

      if (!this.firstName) {
        this.errors.push("First name required.");
      }
      if (!this.lastName) {
        this.errors.push("Last name required.");
      }
      if (!this.email) {
        this.errors.push("E-mail address required.");
      } else if (!isEmail(this.email)) {
        this.errors.push("This e-mail address is invalid.");
      }

      if (!this.vat && this.company) {
        this.errors.push("VAT Number required");
      } else if (this.vat && this.company) {
        this.businessOrder = true;
      }

      if (this.errors.length == 0) {
        this.orderProcess = 'selectCountry';
      }
    },
    checkout() {

      let total = (this.$store.state.cart.length * this.licenseSum + this.vatSum);


      // add licenses below
      const orderData = {
        order: this.order,
        firstName: this.firstName,
        lastName: this.lastName,
        companyName: (this.companyName != undefined ? this.companyName : ''),
        street: (this.street != undefined? this.street : ''),
        postal: (this.postal != undefined? this.postal : ''),
        city: (this.city != undefined? this.city : ''),
        country: (this.country != undefined? this.country : ''),
        business: (this.businessOrder != null? this.businessOrder : false),
        VATcountry: this.VATcountry,
        VATnumber: (this.vat != undefined? this.vat : ''),
        email: this.email,
        x: total
      };
      this.serverOrder(orderData);
      this.orderProcess = 'loadingCheckout';
    },
    async serverOrder(orderData) {
      // this needs finishing

      return await axios.get(`${process.env.VUE_APP_BASE_URL}order/?order=${orderData.order}&firstName=${orderData.firstName}&lastName=${orderData.lastName}&companyName=${orderData.companyName}&street=${orderData.street}&postal=${orderData.postal}&city=${orderData.city}&country=${orderData.country}&x=${orderData.x}&vatCountry=${orderData.VATcountry}&vatNumber=${orderData.VATnumber}&business=${orderData.business}&email=${orderData.email}`)
      .then((response) => {
        window.location.href = response.data.checkout;
      })
    },
  },
  computed: {
    order() {
      let styles = [];
      for (let style of this.$store.state.cart) {
        styles.push(style.id.slice(5));
      }
      return styles.join(', ');
    },
    vatSum() {
      let vat;
      if (this.VATcountry == 0) {
        vat = 0.21 * this.licenseSum;
      }

      if (this.VATcountry == 1) {
        if (this.businessOrder) {
          vat = 0;
        } else {
          vat = 0.21 * this.licenseSum;
        }
      }

      if (this.VATcountry == 2) {
        vat = 0;
      }
      return vat;
    },
    licenseSum() {
      let D;
      let W;
      let A;
      let S;
      
      switch (this.desktopLicense) {
        case "0":
          D = 60;
          break;
        case "1":
          D = 90;
          break;
        case "2":
          D = 120;
          break;
        case "3":
          D = 180;
          break;
        case "4":
          D = 240;
          break;
        case "5":
          D = 300;
          break;
        default:
          D = 60;
          break;
      }

      switch (this.webLicense) {
        case "0":
          W = 0;
          break;
        case "1":
          W = 60;
          break;
        case "2":
          W = 100;
          break;
        case "3":
          W = 200;
          break;
        case "4":
          W = 300;
          break;
        case "5":
          W = 500;
          break;
        default:
          W = 0;
          break;
      }

      switch (this.appLicense) {
        case "0":
          A = 0;
          break;
        case "1":
          A = 120;
          break;
        case "2":
          A = 180;
          break;
        case "3":
          A = 240;
          break;
        case "4":
          A = 300;
          break;
        case "5":
          A = 360;
          break;
        default:
          A = 0;
          break;
      }

      switch (this.socialLicense) {
        case "0":
          S = 0;
          break;
        case "1":
          S = 60;
          break;
        case "2":
          S = 180;
          break;
        case "3":
          S = 240;
          break;
        case "4":
          S = 360;
          break;
        case "5":
          S = 540;
          break;
        default:
          S = 0;
          break;
      }
      return D + W + A + S;
    }
  }
}
</script>

<style>
#cart-container {
  height:100%;
  display: flex;
  flex-direction: row;
}

#empty {
  flex-basis: 100%;
  margin: 0 auto;
}

#cart, #licensing, #customer-info {
  flex-basis: 50%;
  display: flex;
  flex-direction: column;
}

#cart {
  margin-right:10px;
}

#licensing, #customer-info {
  margin-left:10px;
}

#loading {
  flex-basis: 100%;
}

.drop-down-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column !important;
}

.customer-info-container {
  height: 100%;
}

.grid-container {
  flex-basis: 100%;
  display: flex;
  flex-direction: column;
  /* height: 90%; */
  overflow-Y: auto;
}

#errors span {
  color: #adadad;
  font-size: 1rem;
}

.grid-container .row {
  display: flex;
  flex-direction: row;
  border-bottom: 3px dotted var(--interface-slider);
  padding:10px;
  text-align: left;
}


/* License Form */

.drop-down-container .row {
  border-bottom: none;
  padding: 0 0 1em 0;
  flex-grow: 0;
}

.drop-down-container .row p {
  flex-basis:70%;
  flex-grow:0;
  flex-shrink: 0;
}

.drop-down-container .row select {
  flex-basis:30%;
  flex-grow:0;
  flex-shrink: 0;
  max-width:30%;
}

select {
  font-size: 1.2em;
}

#customer-info .row {
  flex-direction: column;
}


input {
  background-color: var(--interface);
  flex-basis: 100%;
  border-width: 0px 0px 1px 0px;
  padding: 0;
  font-size: 1.4em;
  box-shadow: inset 0px 0px 8px 4px rgb(255, 255, 255);
}





.check-out {
  position:relative;
  bottom: 0px;
  right: 0px;
  width: 100%;
  padding:1px;
  margin-top: 10px;
  flex-basis: auto;
}

.row .title {
  text-transform: capitalize;
}

.row img {
  height: 6rem;
}

@media only screen and (max-width: 768px) {
  #cart-container {
    display: block;
    height: unset;
    overflow: auto;
    padding:max(80px, 5.6vw) 1rem 30px 1rem;
    /* flex-direction: column; */
  }

  #cart {
    flex-basis: 50%;
    margin-right: 0px;
    margin-bottom: 40px;
  }

  .row img {
    height: 2.5rem;
  }

  .drop-down-container .row p {
    flex-basis: 60%;
  }

  .drop-down-container .row select {
    flex-basis: 40%;
    max-width:40%;
}

  #licensing, #customer-info {
    margin-left: 0px;
    flex-basis: 50%;
  }

  select {
    font-size: 1rem;
  }
}


</style>