<template>
  <div class="page-padding">
    <div v-html="terms" class="text-content">
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      terms: null
    }
  },
  mounted() {
    fetch(`${process.env.VUE_APP_BASE_URL}cms/api/entries?id=terms-and-conditions&token=522ed707c8c8490f117c3d037998f601`)
    .then((response) => response.json())
    .then((data) => {
      this.terms = data.data.content;
    })
  }
}
</script>

<style>

</style>